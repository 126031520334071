<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
    <div class="relative">
      <div>
        <div class="bg-primary flex justify-between p-3">
          <div class=" font-medium text-white heading-3">
            {{detail.orgName}}
          </div>
        </div>
        <div class="p-4">
          <div v-if="updatedLOgo !== null" class="flex justify-center mb-4">
            <div class="text-center">
              <img style="height: auto;width: 120px;" :src="`${updatedLOgo.imageToCrop}`" class="scroll-img-view">
             <div class="flex justify-between">
                <span @click="showLogoCropper = true" class="text-primary cursor-pointer">Change</span>
                <span @click="deleteLogo" class="text-error note_icn cursor-pointer heading-5">
                    <i class="fa-regular fa-trash-can"></i>
                    </span>
              </div>
            </div>
          </div>
          <div v-else class="flex justify-center mb-4">
            <div v-if="logoThumbPath !== ''" class="text-center">
              <img style="height: auto;width: 120px;" :src="`${detail.pathPrefix}${logoThumbPath}/image200x200.jpg`" class="scroll-img-view">
              <div class="flex justify-between">
                <span @click="showLogoCropper = true" class="text-primary cursor-pointer">Change</span>
                <span @click="deleteLogo" class="text-error note_icn cursor-pointer heading-5">
                    <i class="fa-regular fa-trash-can"></i>
                    </span>
              </div>
            </div>
            
            <div v-else class="text-center">
              <img style="height: auto;width: 120px;" src="@/assets/images/companyLogo.png" class="scroll-img-view">  
              <div class="flex justify-between">
                <span @click="showLogoCropper = true" class="text-primary cursor-pointer">Change</span>
                <span @click="deleteLogo" class="text-error note_icn cursor-pointer heading-5">
                    <i class="fa-regular fa-trash-can"></i>
                    </span>
              </div>
            </div>
          </div>

          <div class="sm:grid md:grid lg:grid grid-cols-12 gap-3">
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'cusfirstnameida'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="sendObj.orgName"
                :fieldError="errObj.orgNameErr !== ''"
                :placholderText="`Organization Name`"
                :lableText="'Organization Name'"
                :autoFocus="false"
                @keyPressAction="errObj.orgNameErr = ''"
                @blurAction="sendObj.orgName !== '' ? errObj.orgNameErr = '' : null"
                @inputChangeAction="(data) => sendObj.orgName = data"  />

                <p class="text-error heading-7">{{errObj.orgNameErr}}</p>
            </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'cuslastnameids'"
                :textMaxlength="30"
                :showcharLimit="true"
                :inputext="sendObj.taxNumber"
                :fieldError="errObj.textCodeErr !== ''"
                :placholderText="`Tax/Code`"
                :lableText="'Tax/Code'"
                :autoFocus="false"
                @keyPressAction="errObj.textCodeErr = ''"
                @inputChangeAction="(data) => sendObj.taxNumber = data"  />

                <p class="text-error heading-7">{{errObj.textCodeErr}}</p>
            </div>
            <div class=" col-span-12">
              <TextAreaInput
                :inputId="'discriptioncon'"
                :inputext="sendObj.notes"
                :placholderText="`Additional Notes for invoice`"
                :lableText="'Additional Notes for invoice'"
                :inputType="'text'"
                :autoFocus="false"
                :cols="50"
                :rows="4"
                :setReadOnly="false"
                @inputChangeAction="(data) => sendObj.notes = data"  />
            </div>
            <div class="col-span-12 mb-4 cursor-pointer" @click="clickOnTime()">
               <p class="label_css">Time Zone</p>
                  <div type="text" @click="clickOnTime()"
                  class="flex items-center cursor-pointer outline-none border border-gray2 text-text1 rounded cust_text_field w-full h-14 pl-2 p-2.5 focus:border-primary focus:ring-1 focus:ring-primary" 
                  placeholder="Time Zone">{{ sendObj.timeZone }}</div>
            </div>
          </div>
          <div class="cursor-pointer text-primary -mt-2" @click="setTimeDevice()">Set Device Time Zone</div>
        </div>
      </div>
        <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
          <div class="text-rigth flex gap-2 mb-3">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="editOrgDetail()"/>
          </div>
          </div>
      </div>
  </div>
  <div v-if="openTimePopup" class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 600px; max-height: 85%;">
      <div class="bg-primary flex p-3 text-center justify-center sticky top-0">
        <div class=" font-medium text-white heading-3">
          Select Time Zone
        </div>
      </div>
      <div style="max-height: 60vh" class="overflow-auto">
        <div class="p-2 px-4">
          <div v-for="(data, index) in timeZoneList" :key="index">
            <div class="flex items-center py-2 my-2" :id="data.value">
              <input type="radio" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" v-model="radioModel" name="radio1" :value="data.value" @click="clickOnRadio(data)">
              <div class="pl-3">
                <div class="flex text-text2 "><p>{{data.text}}</p></div>
                <div class="flex text-gray3 "><p>{{data.value}}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
        <div class="text-rigth flex gap-2 mb-3">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="closetime(false)"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="closetime(true)"/>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showLogoCropper" fullscreen>
      <ClipperModal :imageToCrop="profileImageUrl" :title="'Company Logo'" :ratio="4/4" v-if="showLogoCropper"/>
    </div>
</div>
</template>
<script>
import timeList from '@/assets/timeZone.json'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
import ClipperModal from './components/ClipperModal.vue'
export default {
components: {
  TextAreaInput,
  Button,
  TextField,
ClipperModal
},
data () {
  return {
    radioModel: '',
    selectedTimeZone: {},
    openTimePopup: false,
    showLogoCropper: false,
    updatedLOgo: null,
    logoThumbPath: '',
    sendObj: {
      organizationDetailId: 0,
      orgName: '',
      taxNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      timeZone: '',
      notes: '',
    },
    errObj: {
        orgNameErr: '',
        textCodeErr: '',
        address1Err: '',
        address2Err: '',
        stateErr: '',
        zipErr: '',
        countryErr: '',
        otherNumberErr: '',
        emailErr: ''
    }
  }
},
props: ['detail'],
 created() {
},
mounted () {
   this.$root.$on('closeProfileDialog', (data) => {
    console.log('data--------------------', data);
      if (data.isUpdated) {
        this.updatedLOgo = data
      }
      this.showLogoCropper = false
    })
  console.log('Intl.DateTimeFormat', Intl.DateTimeFormat().resolvedOptions().timeZone);
  this.timeZoneList = timeList
  if (this.detail !== null) {
    this.sendObj.zip = this.detail.zip
    this.sendObj.timeZone = this.detail.timeZone
    this.sendObj.state = this.detail.state
    this.sendObj.organizationDetailId = this.detail.organizationDetailId
    this.sendObj.orgName = this.detail.orgName
    this.sendObj.taxNumber = this.detail.taxNumber
    this.sendObj.notes = this.detail.notes
    this.sendObj.country =  this.detail.country
    this.sendObj.city =  this.detail.city
    this.sendObj.addressLine1 =  this.detail.addressLine1
    this.sendObj.addressLine2 =  this.detail.addressLine2
    this.sendObj.fileVaultId =  0
    this.logoThumbPath = this.detail.thumbPath
  }
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  deleteLogo () {
    this.sendObj.fileVaultId = this.detail.fileVaultId
    this.updatedLOgo = null
    this.logoThumbPath = ''
  },
  setTimeDevice () {
    console.log('timeZone-------------------->', Intl.DateTimeFormat().resolvedOptions().timeZone)
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (timeZone === 'Asia/Calcutta') {
      this.sendObj.timeZone = 'Asia/Kolkata'
    } else {
      this.sendObj.timeZone = timeZone
    }
  },
  clickOnRadio (data) {
    this.selectedTimeZone = data
  },
  closetime (value) {
    if (value) {
      this.sendObj.timeZone = this.selectedTimeZone.value
      this.openTimePopup = false
    } else {
      this.openTimePopup = false
    }
  },
  clickOnTime () {
    this.radioModel = this.sendObj.timeZone
    this.openTimePopup = true
    setTimeout(() => {
      document.getElementById(this.sendObj.timeZone).scrollIntoView({behavior: "smooth",block: 'center'})
    }, 170);
  },
  editOrgDetail () {
    let isValid = true
    if (this.sendObj.orgName.trim() === '') {
      isValid = false
      this.errObj.orgNameErr = 'Organization name is required'
    }
    // if (this.sendObj.taxNumber.trim() === '') {
    //   isValid = false
    //   this.errObj.textCodeErr = 'Tax/Code is required'
    // }
    // if (this.sendObj.addressLine1.trim() === '') {
    //   isValid = false
    //   this.errObj.address1Err = 'Address Line 1 is required'
    // }
    // if (this.sendObj.addressLine2.trim() === '') {
    //   isValid = false
    //   this.errObj.address2Err = 'Address Line 2 is required'
    // }
    // if (this.sendObj.state.trim() === '') {
    //   isValid = false
    //   this.errObj.stateErr = 'State is required'
    // }
    // if (this.sendObj.zip.trim() === '') {
    //   isValid = false
    //   this.errObj.zipErr = 'Zipcode is required'
    // }
    // if (this.sendObj.country.trim() === '') {
    //   isValid = false
    //   this.errObj.countryErr = 'Country name is required'
    // }
    // if (this.errObj.orgNameErr !== '' || this.errObj.textCodeErr !== "" || this.errObj.address1Err !== '' || this.errObj.address2Err !== '' || this.errObj.stateErr !== '' || this.errObj.zipErr !== '' || this.errObj.countryErr !== '') {
    //   isValid = false
    // }
    if (this.errObj.orgNameErr !== '') {
      isValid = false
    }
    if (isValid && this.updatedLOgo !== null) {
      this.sendObj.fileVaultId = this.detail.fileVaultId 
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
       MyJobApp.EditOrgDetailWithLogo(
        this.updatedLOgo.file,
        this.sendObj,
        response => {
          this.$root.$emit('closeEditPopup', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    } else if (isValid) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
       MyJobApp.EditOrgDetail(
       this.sendObj,
        response => {
          this.$root.$emit('closeEditPopup', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
  discard () {
    document.body.style = 'overflow: visible;'
    this.$root.$emit('closeEditPopup', false)
  },
},
beforeDestroy () {
    this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>

</style>